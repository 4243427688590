import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";

import {API_URL} from '../../actions/_request';
import {api} from '../../actions/_request';
import _ from "lodash";

import * as actions from '../../actions/user';
import swal from 'sweetalert2';
import moment from 'moment';
import ModalPrecios from './modal_precios';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,Badge, Table 
} from "reactstrap";
import Select from 'react-select';
class Users2 extends Component {
  render() {
    return (
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}


const vistas = [
    {label: 'Inventario', value: 1},
    {label: 'Ventas', value: 2},
    
];

const requestData = (pageSize, page, sorted, filtered,almacen) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var descripcion='';
       
        var codigo='';
        
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        var patt = new RegExp("(si|s)", "i"); 
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
                       
            case "descripcion":
              descripcion=filtered[i].value;
              console.log('descripcion--->',filtered[i].value);
              break;
            case "codigo":
              codigo=filtered[i].value;
              console.log('codigo--->',filtered[i].value);
              break; 
          }
        }
              
              api().get(`/getarticulos?&descripcion=${descripcion}&codigo=${codigo}&almacen=${almacen}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};



class Articulos extends Component {

  constructor(props) {

        super(props);

        this.state = {
             pages: null,
             loading: true,
             almacen:{label: 'Todos', value: ''},
             vista:{label: 'Inventario', value: 1},
        };

        this.fetchData = this.fetchData.bind(this);
        this.reset_password = this.reset_password.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.table = React.createRef();
  }


    toggle_modal=(index=0)=>{  

        this.setState({
            modal_precios : !this.state.modal_precios,
            articulo_id:index,
                    
        })
    } 
    recargar=()=>{
      this.fetchData(this.table.current.state)
    }
  

  handleSelectChange(select, name) {
        const value = select === null ? null : select.value;
        console.log('value---->',value)
        console.log('name---->',name)
        let self=this;

        self.setState({
                [name]: select,
            }, () => {
           this.fetchData(this.table.current.state)
        });

    }
    reset_password(id,nombre){
        

        // console.log('Factura------>',factura)
      let self = this;
      swal.queue([{
        title: 'Favor de Confirmar ',
        text: " Se pondra como contraseña el rfc al Usuario:"+nombre,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get(`/reset_password/${id}`)
        .then(function(response)
        {
          if(response.status === 200)
          {
               swal.insertQueueStep({
                        type: 'success',
                        title: 'Se restablecio Correctamente la Contraseña'
                      })

           
          }
        }).catch(() => {
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])


        
    }

     fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered,
      this.state.almacen.value,
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }

  componentDidMount(){
    

    let _self = this;
    let estado = this.state;
    api().get("/getalmacenesselect")
      .then(function(response){
        console.log('response--->',response.data)
            let final=response.data.unshift({value:'',label:'Todos'});
          _self.setState({
              almacenes: response.data,
              
            });
        
      });
    

  }


  render() {
    const {  pages, loading} = this.state;
    console.log('state',this.state)
    return (
      <div className="content">
        <Row>
          <Col >
            <Card>
              <CardHeader>
              <Row>
              <Col xs={4}>
                <label  className=" col-form-label">vistas : </label>
                      <Select
                            placeholder=""
                            type="text"
                            name="vista"
                            options={vistas}
                            value={this.state.vista}
                            onChange={ (select) => { this.handleSelectChange(select, 'vista') } }
                            clearable={false}

                        />
              </Col>
               {
                this.state.vista.value==1?
              <Col xs={4}>

                <label  className=" col-form-label">Almacen : </label>
                      <Select
                            placeholder=""
                            type="text"
                            name="almacen"
                            options={this.state.almacenes}
                            value={this.state.almacen}
                            onChange={ (select) => { this.handleSelectChange(select, 'almacen') } }
                            clearable={false}

                        />
              </Col>:<Col xs={4}></Col>
            }
              
              <Col className='text-right' xs={4}>
              <Link to="/app/articulos/nuevo">
                <Button color="primary" className="mb-2" >
                    Agregar articulo
                </Button>
              </Link>
              </Col>
              </Row>
              </CardHeader>
              <CardBody>


              {
                this.state.vista.value==1&&
                <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                              
                                                  this.props.history.push('/app/articulos/editar/'+rowInfo.original.id);
                                             
                                              
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                                {Header: '#', accessor: 'codigo',maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Descripción', accessor: 'descripcion'},
                                {Header: 'Existencia', accessor: 'existencia',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Precio 1', accessor: 'precio1',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Precio 2', accessor: 'precio2',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                               
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:150,minWidth:150,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(evt)=>{this.props.history.push('/app/articulos/editar/'+row.original.id); }}>
                                                    Editar
                                                </Button>
                                               
                                            </div>
                                        )
                                    }
                                }
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                              ref={this.table}
                              defaultPageSize={25} 
                              
                              
                             
    
                            />
              }
               {
                this.state.vista.value==2&&
                <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                              
                                                  this.toggle_modal(rowInfo.original.id);
                                             
                                              
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                                {Header: '#', accessor: 'codigo',maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Descripción', accessor: 'descripcion',minWidth:300},
                                
                                {Header: 'Precio 1', accessor: 'precio1',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Precio 2', accessor: 'precio2',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Precio 3', accessor: 'precio3',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Precio 4', accessor: 'precio4',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Precio 5', accessor: 'precio5',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Costo', accessor: 'precio6',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                               
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:150,minWidth:150,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(evt)=>{this.toggle_modal(row.original.id); }}>
                                                    Editar
                                                </Button>
                                               
                                            </div>
                                        )
                                    }
                                }
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                              ref={this.table}
                              defaultPageSize={25} 
                              
                              
                             
    
                            />
              }
              

              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.modal_precios&&
          <ModalPrecios open={this.state.modal_precios} recargar={this.recargar}
                    toggle={this.toggle_modal}  articulo_id={this.state.articulo_id}/>
        }
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
        auth:state.auth,
    }
};

export default connect(mapStateToProps, actions)(Articulos)
